<template>
   <div class="main">
     <img :src="require('../assets/images/bg1.jpg')" class="bg" />
     <viewHeader/>

     
    <div class="row">
      <h1 class="text-white">My Purchase Transactions</h1>
      <div class="row">
        <div class="box">
          <div class="d-modal">
            <label for="fromDate" class="text-white">From Date:</label>
            <datepicker v-model="fromDate" :value="fromDate" format="MM/dd/yyyy" id="fromDate" class="form-control "></datepicker>
          </div>
        </div>
        <div class="box">
          <div class="d-modal">
            <label for="toDate" class="text-white" >To Date:</label>
            <datepicker v-model="toDate" :value="toDate" format="MM/dd/yyyy" id="toDate" class="form-control"></datepicker>
          </div>
        </div>
        <div class="box">
          <div class="d-modal">
            <label class="text-white">Status:</label>
            <select name="status" @change="onChange($event)" v-model="init.status" class="form-select form-control">
            <option disabled>Select Status</option>
            <option value="00" >PENDING</option>
            <option value="11" >SUCCESS</option>
            <option value="99" >FAILED</option>
          </select>
          </div>
        </div>
      </div>
     
      <div class="row">
        <table id="card" class="table">
                     
          <thead class="thead text-left">
                    <tr >
                    <th>TIMESTAMP</th>
                    <th>TXN ID</th>
                    <th>CURRENCY BOUGHT</th> 
                    <th>AMOUNT</th> 
                    <th>STATUS</th>
                    <th>ACTION</th>
                    </tr> 
              
            </thead>
           <tbody v-if="isLoading">
            <td>
              <b-icon
                icon="arrow-clockwise"
                animation="spin"
                font-scale="4"
              ></b-icon
              >
            </td>
           </tbody>
            <tbody >
              <tr v-for="list in history" :key="list.id" >
                <td @click="openDetails(list.txnId)">{{formatDate(list.txnDate)}}</td>
                <td @click="openDetails(list.txnId)">{{list.txnId}}</td>
                <td @click="openDetails(list.txnId)">{{list.sourceCurrency}}</td> 
                <td @click="openDetails(list.txnId)">{{list.targetAmount}}</td> 
                <td @click="openDetails(list.txnId)">{{list.txnStatus}}</td>
                <td @click="openDetails(list.txnId)"><button class="btn btn-primary">View</button></td>
            </tr>
            </tbody> 
            
      </table> 
       <table v-if="length == 0">
              <thead  class="no-txn text-center">No transactions found!</thead>
              <thead  class="no-txn text-center">Please enter date and status.</thead>
      </table> 
      </div>
      
       
    </div>  

  
    <LogoutModal @close="openLogout" v-if="showLogoutModal"/>
    <Details @close="openDetails(txnId)" v-if="showDetails" :txnId="txnId"/>

  </div>
</template>

<script>

  import qs from 'querystring'
  import numeral from 'numeral'
  import moment from 'moment';
  import LogoutModal from '../components/modals/LogoutModal.vue'
  import Details from '../components/modals/Details.vue'
  import Datepicker from 'vuejs-datepicker';
  import viewHeader from '../views/viewHeader.vue'

  export default {
    name: 'TransactionHistory',
    components: {
      LogoutModal,
      Details,
      Datepicker,
      viewHeader
    },
    data: function() {
      const today = new Date();
      return  {
        
        isLoading: false,
        fromDate: today,
        toDate: today,
        
        userId:'',
        length:'',
        showLogoutModal: false,
         showDetails: false,
         txnId:'',
         status:'00'
       

        
      }
    },
    computed: {
      init() {
          return this.$store.state.init;
      },
    },
   
    methods: {
      goHome(){
              this.$router.push({name: 'Dashboard'})
               
            },
         profile(){
                 this.$router.push({name: 'Kyc'})
               
            },
         history(){
                 this.$router.push({name: 'TransactionHistory'})
               
            },
       openLogout(){
        this.showLogoutModal = !this.showLogoutModal;
      },
    
      onChange(event){
            const selectedStatus = event.target.value;
            this.selectedStatus = selectedStatus
            this.getHistory();
          //  console.log(this.selectedStatus)
            
      },


      formatDate: function(date) {
        return moment(date).format('MM/DD/yyyy hh:mm:ss A');
      },
      formatAmount: function(num){
        return numeral(num).format('0,0.00000000');
      },
   

       openDetails(txnId){
         this.txnId = txnId
         this.showDetails = !this.showDetails
         
      },
     

      getHistory(){
        this.isLoading = true
      const params = {
       	  fromDate: moment(this.fromDate).subtract(1, 'days').format('MM/DD/yyyy'),
					toDate: moment(this.toDate).add(1, 'days').format('MM/DD/yyyy'),
					rowsPerPage: 50,
					page: 1,
					status: this.selectedStatus
      }

       const data = Object.keys(params)
            .map((key) => `${key}=${encodeURIComponent(params[key])}`)
            .join('&');
		   
        const options = {
		          method: 'POST',
		          headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId")  },
		          data,
		          url: '/cx/buycrypto/history',
		        };

            this.axios(options)
	        	.then((response) => {  
	            //	console.log(response.data);
              this.isLoading = false
                this.history = response.data.rowsList
              //  console.log(response.data.rowsList)

                this.length = response.data.rowsList.length
              //  console.log(response.data.rowsList.length)
	        	}).catch((err) => {
	            //	console.log(err.response.data)
                this.init.error.msg = err.response.data.msgCode
                

	       	 	})

      },
      defaultHistory(){
		 const params = {
       	fromDate: moment(this.fromDate).subtract(1, 'days').format('MM/DD/yyyy'),
					toDate: moment(this.toDate).add(1, 'days').format('MM/DD/yyyy'),
					rowsPerPage: 50,
					page: 1,
					status: '00'
      }

       const data = Object.keys(params)
            .map((key) => `${key}=${encodeURIComponent(params[key])}`)
            .join('&');
		   
        const options = {
		          method: 'POST',
		          headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId")  },
		          data,
		          url: '/cx/buycrypto/history',
		        };

            this.axios(options)
	        	.then((response) => {  
	            //	console.log(response.data);
                this.history = response.data.rowsList
              //  console.log(response.data.rowsList)

                this.length = response.data.rowsList.length
              //  console.log(response.data.rowsList.length)
	        	}).catch((err) => {
	            //	console.log(err.response.data)
                this.init.error.msg = err.response.data.msgCode
                

	       	 	})


      },

       getProfile: function(){
         const options = {
            method: 'GET',
            headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
            url: '/cx/profile/',
          };

          this.axios(options)
            .then((response) => {
               
              this.init.profile = response.data;
               this.userId = response.data.entityId
               this.status = response.data.verificationStatus

              })
      },
     checkSession: function() {

        const options = {
          method: 'POST',
          headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
          url: '/cx/sessioncheck',
        };

        this.axios(options)
           .then((response) => { 
            this.getProfile();
            this.init.hasSession = true;
              setTimeout( () => {
                this.showLoading = false;
              },1000)
            }).catch((err) => {
              //  console.log(err.response.data)
              if(err.response.data.msgCode == '00001') {
                window.location.href = "/#/";
              }  

              if(err.response.data.msgCode == '00020') {

                setTimeout( () => {
                window.location.href = "/#/setup-profile";
               },1000)
                
              }  

            })
      },

      logout: function(){
        this.axios.get('/cx/logout')
            .then((response) => { 
              
              window.location.href = "/#/";
                
            }).catch((err) => { 
             // console.log(err)

            })
      }
    },

    beforeMount(){
         this.checkSession();
         
      
    },
    mounted(){
      // this.getCurrentDateTime();
      this.defaultHistory();
     
     
    },
    

    watch: {
      'this.selectedStatus': function(newValue, oldValue) {
            if (newValue != oldValue) {
                this.getHistory();
            }
        },
        
      
    }
  }
</script>

<style scoped>
 
  .d-modal{
    padding:20px !important;
    margin: auto 10px !important;
    background-color: transparent !important;
    height: 120px !important;
    color: white !important;
  }

* {
 box-sizing: border-box;
 
}

.form-check-input{
 width: 1em !important;
 height: 1em !important;
}
/* Create two equal columns that floats next to each other */
.column {
 float: left;
 width: 50%;
 padding: 10px;
 margin-left: 10px;

}
.row{
  margin-left: 15px;
}

/* Clear floats after the columns */
.row:after {
 content: "";
 display: table;
 clear: both;
 background: transparent !important;
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
 .column {
   width: 100%;
 }
}

   .datepicker {
      width: 100% !important;
      /* max-width: 200px; */
      margin-bottom: 10px;
      padding-right: 10px;
}
.datepicker-container {
    width: 100%;
    display: flex;
    justify-content: end;
    background: transparent !important;
  }

@media (min-width: 576px) {
  .datepicker {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .datepicker {
    max-width: 400px;
  }
}

@media (min-width: 1200px) {
  .datepicker {
    max-width: 500px;
  }
}
.table{
     
        border-collapse: collapse;
        width: 100% !important;
        margin: 0 20px;
        background: #5227731d !important;
        color: white;
        text-align: center !important;
        font-size: 80%;
        border-radius: 10px !important;
        padding: 0.5em 2em !important;
        overflow-y: scroll;
        max-height: 700px; 
}

th, td {
  padding: 0.5em 0.5em !important;
  text-align: left !important;
}
.no-txn{
  display: flex !important;
  justify-content: center !important;
  width: 100% !important;
  margin: 0 auto;
  text-align: center;
  color: grey;
}
tr{
  border-top: 1px solid grey;
  width: 100%;
  padding: 0.5em 2em;
  text-align: left !important;
}


 
 
  
   @media only screen and (max-device-width: 760px) {
     .navbar > container-fluid{
      display: flex !important;
     }
     .col-7{
       width: 100%;
       display: block !important;
       margin: 10px !important;
     }
     .date{
       width: 100% !important;
       display: flex !important;

     }
    
  

   }
  
   @media (min-width: 576px) and (max-width: 767.98px) { 
    *{
        overflow-x: hidden;
    }
     
  }
  @media only screen and (min-width: 1008px) {
    .datepicker-container{
      display: flex !important;
    }

   }
  
   @media only screen and (min-width: 275px) and (max-width: 1007px) {
    .datepicker-container{
      display: block !important;
      justify-content: center !important;
      margin: 0 auto !important;
      
    }
    .datepicker-container .vdp-datepicker .form-select{
      width: 100% !important;
      max-width: 100% !important;
    }
    .form-select{
        width: 100%;
        max-width: 100% !important;
        margin-bottom: 10px;
        height: 50px;
      }
      .btn-go{
        width: 100%;
        max-width: 100% !important;
        height: 50px;
        margin-bottom: 10px;
        background: rgb(2,11,54);
        color: white;
      }
      .d-modal{
        padding:0 !important;
        margin: 0 !important;
        background-color: transparent !important;
        height: auto !important;
        color: white !important;
        width: 90%;
  }
   
  }
  .box {
  padding: 0.1rem;
  border-radius: 6px !important;
  margin-bottom: 10px;
  background-color: transparent !important;

  }

  label {
  display: flex;
  font-size: 16px;
  }


@media all and (min-width: 35rem) and (max-width: 60rem) {
 
    .box {
    float: left;
    width: 33%;
    }
    .box2 {
    float: left;
    width: 50%;
    }

  
}

@media all and (min-width:60rem) {
  
  
    .box2 {
    float: left;
    width: 50%;
    }
    .box {
    width: 33%;
    float: left;
    }

  
}

</style>




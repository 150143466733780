<template>

   <div class="backdrop">
     <div class="d-modal">
        <div class="modal-header text-right">
          <div class="modal-title">
            <h6 class="text-white">Transaction details {{txnId}}</h6>
          </div>
          
        </div>
        <div class="p-2">
          <div class="row">
            <h5 class="card-title text-uppercase mb-3 text-white">Order summary</h5>
            <div class="column">
              <div class="l-modal">
                <div class="d-flex justify-content-between">
                  <div>
                    <h5 for="product-tag">You will receive: </h5>
                  </div>
                  <h5 for="product-price">  {{receiptCrypto}} 
                    {{receiptCryptoAmount}}</h5>
                </div>
              </div>
            </div>
            <div class="column">
              <div class="l-modal">
                <div class="d-flex justify-content-between">
                  <div>
                    <h5 for="product-tag">You will pay: </h5>
                  </div>
                  <h5 for="product-price">{{receiptFiat}}
                    {{formatAmount(receiptFiatAmount )}}</h5>
                </div>
    
              </div>
            </div>
          </div>
          <div class="row">
            <div class="column">
              <div class="l-modal">
                <div class="bank text-white">
                  <div class="bank text-white">
                    <h5 class="label">Txn ID:</h5>
                    <h5>{{txnId}}</h5>
                  </div>
                  <h5 class="label">Date and Time:</h5>
                  <h5>{{receiptTxnDate}}</h5>
                </div>
                <div class="bank text-white">
                  <h5 class="label">Locked Price:</h5>
                  <h5>{{formatAmount(receiptLockedPrice)}}</h5>
                </div>
               
                <div class="bank text-white">
                  <h5 class="label">Payment Method:</h5>
                  <h5>{{receiptPaymentMethod}} - {{receiptProvider}}</h5>
                </div>
                <div class="bank text-white">
                  <h5 class="label">Reference Number:</h5>
                  <h5>{{receiptReferenceId}}</h5>
                </div>
                <div class="bank text-white">
                  <h5 class="label">Status:</h5>
                  <h5>{{receiptPaymentStatus}}</h5>
                </div>
                
                
             
            </div>
            </div>
            <div class="column">
              <div class="l-modal">
                <div class="bank text-white">
                  <div class="bank text-white">
                    <h5 class="label">Recipient Wallet Address:</h5>
                    <h5>{{receiptWalletAddress}}</h5>
                </div>
                  <h5 class="label">Recipient Name:</h5>
                  <h5>{{recipientName}}</h5>
                </div>
                <div class="bank text-white">
                  <h5 class="label">Recipient Nationality:</h5>
                  <h5>{{recipientNationality}}</h5>
                </div>
                <div class="bank text-white">
                  <h5 class="label">Relationship to the recipient:</h5>
                  <h5>{{recipientRel}}</h5>
                </div>
                <div class="bank text-white">
                  <h5 class="label">Destination Wallet:</h5>
                  <h5>{{recipientAccount}}</h5>
                </div>
                <div class="bank text-white">
                  <h5 class="label">Purpose of txn:</h5>
                  <h5>{{purposeOfTxn}}</h5>
                </div>
              </div>
            </div>
            <div v-if="receiptPaymentMethod == 'BANK'">
              <h5 class="text-dark" >Kindly send us a receipt of your transaction via email <a href="mailto:deposit@coexstar.com" style="color: red; text-decoration: none;">deposit@coexstar.com</a> validate your cash deposit request. The standard turnaround time for deposit amounting to Php50,000 and below is 5-15 minutes. Please be advised that all bank deposit transactions are manually reviewed and this may cause delays. Kindly indicate your Reference Number as the subject.</h5>
              <b-card>
                <h5>BANK TRANSFER DETAILS:</h5>
                <div class="bank text-dark">
                  <h5>Bank Name:</h5>
                  <h5>Unionbank of the Philippines</h5>
                </div>
                <div class="bank text-dark">
                  <h5>Account Name:</h5>
                  <h5>Aba Global Philippines Inc</h5>
                </div>
                <div class="bank text-dark">
                  <h5>Account Number:</h5>
                  <h5>102730016600</h5>
                </div>
                <div class="bank text-dark">
                  <h5>Branch:</h5>
                  <h5>One Global Place 25th St. 5th Ave. Fort Bonifacio, BGC, Taguig City, 1634</h5>
                </div>
                
              </b-card>

            </div>
          
           
           
            <div class="p-3">
           <div class="d-flex justify-content-center mt-4 mb-4">
            <button class="btn btn-secondary text-uppercase mr-sm-2" @click="closeModal()">CLOSE</button>
            <button class="btn btn-secondary text-uppercase mr-sm-2" @click="viewReceipt()" v-if="receiptPaymentStatus == 'SUCCESS'">view receipt</button>
            </div>
          </div>
              <!-- <p>** VAT is included.</p> -->
            </div>
  
            
        </div>
        </div>
      </div>

  </template>

  <script>
    import numeral from 'numeral'
    import moment from 'moment'
     export default {
       props:["txnId"],
          name: 'Details',
          data(){
              return {
                receiptCrypto: '',
       receiptPaymentMethod: '',
       receiptTxnDate:'',
       receiptPaymentStatus:'',
       receiptFiat:'',
       receiptCryptoAmount:'',
       receiptFiatAmount:'',
       receiptWalletAddress:'',
       receiptComment:'',
       receiptReferenceId:'',
       receiptLockedPrice: '',
       showPending: false,
       countdown: 60,
       vatRate: 12,
       recipientName:'',
       recipientNationality:'Philippines',
       recipientRel:'',
       purposeOfTxn:'',
       recipientAccount:'',
       showCountryForNationality: false,
       receiptProvider:'',
       xchgRate:'',
        phpRate:''


              }
          },
          methods:{
            
            closeModal() {
                this.$emit("close");
            },
             formatAmount: function(amount) {
                    return numeral(amount).format('0,0.000000') 
                },
                formatDate: function(date) {
              return moment(date).format('MM/DD/yyyy hh:mm:ss A');
            },
            viewReceipt(){
              if(this.receiptPaymentStatus == 'SUCCESS'){
                window.location.href="/#/successful/" + this.receiptReferenceId
              }
            },
            getTxnDetails(){
                const params = {
                  txnId: this.txnId
                }
                const options = {
                  method: 'POST',
                  headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
                  params,
                  url: '/cx/txn/buycrypto/details',
                };
                 this.axios(options)
                  .then((response) => {
                 
                 this.receiptReferenceId = response.data.txnId
                  this.receiptLockedPrice = response.data.lockedPrice
                  this.receiptFiat = response.data.targetCurrency
                  this.receiptFiatAmount = response.data.sourceAmount
                  this.receiptPaymentMethod = response.data.paymentMethod
                  this.receiptPaymentDate = response.data.paymentDate
                  this.receiptPaymentStatus = response.data.paymentStatus
                  this.receiptCrypto= response.data.sourceCurrency
                  this.receiptCryptoAmount = response.data.targetAmount
                  this.receiptWalletAddress = response.data.walletAddress
                  this.receiptComment = response.data.txnComment
                  this.recipientAccount = response.data.recipientAccount
                  this.recipientNationality = response.data.recipientNationality
                  this.recipientRel = response.data.recipientRel
                  this.recipientName = response.data.recipientName
                  this.purposeOfTxn = response.data.purposeOfTxn
                  this.receiptTxnDate = response.data.txnDate
                  this.receiptProvider = response.data.provider
                  console.log(this.receiptFiatAmount)
                  console.log(this.receiptFiat)
                  })
            },
            getPhpRate(){
                   const params = {
                           amount: this.receiptFiatAmount,
                           fromCurrency: this.receiptFiat,
                           toCurrency: 'PHP'
                       }

                       const data = Object.keys(params)
                           .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                           .join('&');
                       

                               const options = {
                               method: 'POST',
                               headers: { 'content-type': 'application/x-www-form-urlencoded' },
                               data,
                               url: '/pub/getxchgrate',
                               };


                               this.axios(options)
                               .then((response) => {  
                              this.phpRate = response.data.convertedAmount
                              this.xchgRate = response.data.xchgRate

                              console.log(response.data)
                              
                               }).catch((err) => {

                       })
         },
           
          },
          mounted(){
            this.getTxnDetails();
            this.getPhpRate();
          }

      }

  </script>
  <style scoped>
.d-modal{
    height: auto;
    margin: 100px 50px;
    border-radius: 10px;
    background-image: linear-gradient(  to right,
          #202538 29%,
          #10121C 59%,
          ) !important;
    max-height: calc(100vh - 110px);
    overflow-y: auto;
}
.modal-header{
    width: 100%;
    background: rgb(2,11,54);
    background-image: linear-gradient(  to right,
          #202538 29%,
          #10121C 59%,
          ) !important;
  }
.modal-title{
    margin: 0 auto;
    justify-content: center;
  }
.modal-title img{
    width: 4rem;
  }
  
.backdrop{
    top: 0;
    position: fixed;
    background: rgba(0, 0, 0, 0.6);
    width: 100vw;
    height: 100vh;
    z-index: 1;
  }

* {
  box-sizing: border-box;
  
}
.form-check-input{
  width: 1em !important;
  height: 1em !important;
}
/* Create two equal columns that floats next to each other */
.column {
  float: left;
  width: 50%;
  padding: 10px;

}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .column {
    width: 100%;
  }
  .payment{
  display: flex;
  flex-direction: column;
 }
}
.column .star{
  width: 40rem;
  object-fit: contain;
  position: relative;
  margin: 10px auto !important;
}
  label{
    color: white;
    width: 250px;
  }
/*Modal*/
.l-modal{
  color: white;
    height: auto;
    margin: 10px auto !important;
    background-image: linear-gradient(to bottom, #202538 0%, #10121C 97%) !important;
    border-radius: 10px;
    z-index: 2;
    padding: 30px;
  }

 .payment{
  display: flex;
 }
    .mobile-container {
        position: relative;  
    }
    .default-flag {
        width: 33px;
        margin-right:0.25em;
    }
    .btn{
        border: 1px solid #1a2169;
        
    }
    .country-list {
        position: absolute;
        background: #ccc;
        color: black;
        top: 70px;
        padding: 0.5em;
        height: 13em;
        overflow: auto;
        z-index: 3;
    }
    .country-list .row {
        margin: 0 !important;
    }
.label{
  color: gray;
}
    .country-list .col-sm-6 {
      display: flex;
      padding: 0.7em;
      align-items: center;
      cursor: pointer;
      color: black;
    }
    .country-list .row:hover {
      background: darken(#1a2169, 10%);
    }
    .country-name {
      margin-left: 0.5em;
    }
    .country-name span {
        font-size: 0.9em;
    }

    .flag-list-img {
        width: 25px;
    }
   
   


.three-column {
  display: flex;
  justify-content: space-between;
}
.modal-footer{
  border-top: none !important;
}
.form-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.form-column {
  flex-basis: calc(33.33% - 20px);
  margin-bottom: 20px;
}
form{
  padding-top: 3px;
}

@media (max-width: 767px) {
  .form-column {
    flex-basis: 100%;
  }
  .payment{
  display: inline;
 }
}



  .disabled{
  cursor: not-allowed;
}
.input-group{
  flex-wrap: nowrap;
  padding: 0;
}
.input-group .form-control{
  height: auto;
}
fieldset{
  min-height: 50px !important;
}

.legend{
   color: white !important;
    padding-top: 10px;
  }
  h1 {
  text-align: center;
  font-size: 5rem;
  }

  form {
  width: 90%;
  margin: 0 auto;
  }

  .box {
  padding: 0.1rem;
  border-radius: 6px !important;
  margin-bottom: 10px;
  }

  label {
  display: flex;
  font-size: 16px;
  width: 250px;
  }

  input, .form-control, .form-select {
  display: inline-block;
  width: 100%;
  border-radius: 10px !important;
  }

  input:required:invalid, input:focus:invalid {
  background-position: right top;
  background-repeat: no-repeat;
  -moz-box-shadow: none;
  }

  input:required:valid {
  background-position: right top;
  background-repeat: no-repeat;
  }

  #masthead {
    padding: 10rem 0;
    background-size: 100%;
    /*color: #fff;*/;
  }
  .help{
    margin: 2rem;
    
  }
  .bank{
  display: block;
  font-size: 16px;
 }
@media all and (min-width: 35rem) and (max-width: 60rem) {
  .payment{
  display: flex;
 }
  .bank{
  display: flex;
  flex-direction: column;
 }
    .box {
    float: left;
    width: 33%;
    }
    .box2 {
    float: left;
    width: 50%;
    }

    label {
    display: flex;
    width: 250px;
    }

    #masthead {
    }
}

@media all and (min-width:60rem) {
  
    label {
    display: inline;
    width: 250px;
    float: left;
    }
    .box2 {
    float: left;
    width: 50%;
    }
    .box {
    width: 33%;
    float: left;
    }

    input {
    width: 50%;
    }

    #masthead {
    padding: 9rem;
    }
}

  </style>